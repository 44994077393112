import { Modal } from 'bootstrap';

export default class Camera {
    constructor() { }

    initialize(storage, idModal, idBtnShoot, idBtnCamSwitch = null, idBtnFlashToggle = null) {
        console.log('camera.initialize(<storage>,' + idModal + ',' + idBtnShoot + ',' + idBtnCamSwitch + ',' + idBtnFlashToggle + ')');
        let self = this;

        self.streaming = false;
        self.storage = storage;
        self.canvas = document.querySelector('#' + idModal + ' canvas');
        self.video = document.querySelector('#' + idModal + ' video');
        self.modal = new Modal(document.getElementById(idModal), {
            backdrop: 'static',
            keyboard: false
        });
        self.elBtnShoot = document.getElementById(idBtnShoot);
        self.elBtnCamSwitch = document.getElementById(idBtnCamSwitch);
        self.elBtnFlashToggle = document.getElementById(idBtnFlashToggle);
        self.flashStatus = false;

        // Activate the flash toggle
        if (self.elBtnFlashToggle) {
            self.elBtnFlashToggle.onclick = () => { self.toggleFlash() };
        }

        // The video port dimensions (will be updated when the streaming starts)
        self.width = 0;
        self.height = 0;

        if( !self.video.dataset.canplay ) {
            // Function getEventListeners() only works in the debug console, so we have no way to check if the listener was added already
            // So we use dataset.canplay for that and check for its value
            self.video.dataset.canplay = 1;
            self.video.addEventListener( "canplay", () => {
                // Adjust the dimensions of the video stream once the streaming starts
                self = window.application.camera;
                self.width = document.getElementById('cameraWrapper').clientWidth -40;
                self.height = (self.video.videoHeight / self.video.videoWidth) * self.width;

                self.video.setAttribute("width", self.width);
                self.video.setAttribute("height", self.height);
                self.canvas.setAttribute("width", self.width);
                self.canvas.setAttribute("height", self.height);
                self.streaming = true;
            }, false );
        }

        // Get a list of the available devices, so we can change cameras
        self.deviceCurrent = self.storage.get('camera.deviceLastUsed');
        self.constraints = {
            audio: false,
            video: {
                width: { min: 800 },
                facingMode: { ideal: "environment" },
                aspectRatio: { ideal: 16 / 9 },
            },
        };
        self.devices = [];
        navigator.mediaDevices.getUserMedia(self.constraints)
            .then(async () => {
                const devices = await navigator.mediaDevices.enumerateDevices(self.constraints);
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                self.devices = videoDevices;

                if (self.devices.length > 1) {
                    if (self.elBtnCamSwitch) {
                        self.elBtnCamSwitch.hidden = false;
                    }
                }
            });

        // This will hold the function of what to do when the user shoots the image
        self.callback = null;
    }

    start(camera = 'lastUsed', callback) {
        console.log('camera.start(' + camera + ')');
        let self = this;

        // Remember what to do when shooting a picture
        if( callback ) {
            self.callback = callback;
        }

        if (!self.modal._isShown) {
            self.modal.show();
        }
        if( self.devices.length == 0 ) {
            // Hide the viewport, show the warning
            document.getElementById('cameraWrapper').classList.add('collapse');
            document.querySelectorAll('.cameraWarning').forEach( el => el.classList.remove('collapse'));
        } else {
            // Hide the warning, show the video viewport
            document.querySelectorAll('.cameraWarning').forEach( el => el.classList.add('collapse'));
            document.getElementById('cameraWrapper').classList.remove('collapse');

            if( camera.toString().toLowerCase() !== 'lastused' /* && self.devices.length > 1 */ ) {
                // We want to show the next camera (and we have one), so stop the current video tracks (if any, and if running)
                console.log('stopping tracks');
                if (self.video.srcObject) {
                    self.video.srcObject.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                // Find the position of the current device in the list of devices
                let currentPos = self.devices.map(obj => obj.deviceId).indexOf(self.deviceCurrent);
                console.log( 'Current pos: ' +currentPos );
                if (currentPos == -1) {
                    // The last used camera was not found in the list of cameras, set it to the first camera in the list
                    currentPos = 0;
                }

                // Switch to the next camera (if available, and we're currently showing a camera)
                if (currentPos < self.devices.length - 1) {
                    currentPos++;
                } else {
                    currentPos = 0;
                }
                console.log( 'New pos: ' +currentPos );
                console.log( 'Old cam: ' + self.deviceCurrent );
                self.deviceCurrent = self.storage.set('camera.deviceLastUsed', self.devices[currentPos].deviceId);
                console.log( 'New cam: ' + self.deviceCurrent );
            }

            // If we have a device ID, add it to the constraints so we specify specifically what camera to use
            let constrains_specific = self.constraints;
            if( self.deviceCurrent ) {
                constrains_specific.video = {
                    deviceId: { exact: self.deviceCurrent }
                };
            }
            console.log(constrains_specific);

            navigator.mediaDevices.getUserMedia(constrains_specific).then((stream) => {
                // Find the ID of the camera device so that we can switch cameras later
                const track = stream.getTracks().find(track => track.kind === "video");
                self.deviceCurrent = self.storage.set('camera.deviceLastUsed', track.getSettings().deviceId);

                // Start streaming
                self.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                self.video.srcObject = stream;
                self.video.play();

                self.streaming = true;
            }).catch((err) => {
                // Hide the viewport, show the warning
                document.getElementById('cameraWrapper').classList.add('collapse');
                document.querySelectorAll('.cameraWarning').forEach( el => el.classList.remove('collapse'));
                console.error( `An error occurred: ${err}` );
            });
        }
    }

    stop( hideModal = true ) {
        console.log('camera.stop()');
        let self = this;

        try {
            self.streaming = false;
            if (self.video.srcObject) {
                self.video.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        } catch (error) {
            console.error(error);
        }

        if( hideModal ) {
            self.modal.hide();
        }
        return true;
    }

    shoot() {
        console.log('camera.shoot()');
        let self = this;

        const context = self.canvas.getContext("2d");
        if( self.width && self.height ) {
            self.canvas.width = self.width;
            self.canvas.height = self.height;
            context.drawImage( self.video, 0, 0, self.width, self.height );

            let key = 'photos.' +window.application.generateUUID();
            self.storage.saveCanvasAsBlob( key, self.canvas ).then( () => {
                console.log( 'canvas image stored in file storage' );
                //let self = window.application.camera;
                if( self.callback ) {
                    console.log( 'callbacking' );
                    self.callback( key );
                } else {
                    console.log( 'No callback available to - wait for it - call back' );
                }
            });
        }
        self.stop();
    }

    toggleFlash() {
        console.log('camera.toggleFlash()');
        let self = this;

        self.flashStatus = !(self.flashStatus);
        self.video.srcObject.getVideoTracks()[0].applyConstraints({advanced: [{ torch: self.flashStatus }]}).catch(error => {
            console.error(error);
        });
    }

    resetPictureList() {
        // Rebuilds the internal list of pictures in localstorage, "app.pictures"
        console.log('camera.resetPictureList()');
        let self = this;

        return new Promise(function (resolve, reject) {
            try {
                //let currentList = self.storage.get('app.pictures') || {};
                self.storage.blobs().then((blobs) => {
                    let result = {};
                    for (let blob of blobs) {
                        if (blob.substring(0, 7) == 'photos.') {
                            //let createdAt = window.application.GMTToLocal();
                            result[blob] = {
                                key: blob,
                                createdAt: window.application.GMTToLocal(),
                                status: 'local'
                            };
                        }
                    }
                    self.storage.set('app.pictures', result);
                    resolve(result);
                });
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
}